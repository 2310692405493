@import './app/styles/colors.scss';
@import './app/styles/general.scss';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@font-face {
  font-family: 'Poppins';
  src: url(assets/font/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url(assets/font/Poppins-Bold.ttf) format('truetype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
}

body {
  min-height: 100vh;
}

section {
  padding-block: 3rem;
}

.toast-container {
  z-index: 9999999999999 !important;
}

@import './colors.scss';

body {
  font-family: "Poppins";
}

.underline-border {
  border-bottom: 1px solid $underline-color;
}

.text-bold {
  font-family: "Poppins-Bold";
}

.container {
  .title-container{
    padding-top: 1rem;
    position: relative;

    .title-text{
      font-size: 1.3rem;
      color: $title-color;
      font-weight: 500;
      &::before{
        position: absolute;
        content: " ";
        width: 74%;
        height: 3px;
        top: 0;
        bottom: 0;
        right: 0;
        margin-top: 1rem;
        background: $title-separator-color;
        margin: auto;
        transform: translateY(10px);
      }
    }
  }
}
.container-fluid {
  .title-container{
    padding-top: 1rem;
    position: relative;

    .title-text{
      font-size: 1.3rem;
      color: $title-color;
      font-weight: 500;
      &::before{
        position: absolute;
        content: " ";
        width: 74%;
        height: 3px;
        top: 0;
        bottom: 0;
        right: 0;
        margin-top: 1rem;
        background: $title-separator-color;
        margin: auto;
        transform: translateY(10px);
      }
    }
  }
}

.cursor {
  cursor : pointer;
}

.btn-navegador {
  cursor : pointer;
  border : none;
  color: $primary;
  background-color: white;
}

.btn-navegador:hover {
  background-color: $light-background;
}

@media(max-width: 768px) {
  .container{
    .title-container{

      .title-text{
        &::before{
          position: absolute;
          content: " ";
          width: 40%;
          height: 3px;
        }
      }
    }
  }
  .container-fluid{
    .title-container{

      .title-text{
        &::before{
          position: absolute;
          content: " ";
          width: 40%;
          height: 3px;
        }
      }
    }
  }
}

.btn-outline-principal{
  padding: 5px 15px !important;
  color: #007a93;
  font-weight: 600;
  border: 1px solid #007a93;
  border-radius: 6px;
  background-color: #fff;
  transition: all ease-in-out .3s;
}

.btn-aceptar{
  background-color: #585858;
  color: #fff;
  font-weight: 500;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.8rem 1.3rem;
  &:hover {
    background-color: #585858;
  }
}

.btn-outline-principal:hover{
  color: #fff;
  background-color: #007a93;
  transition: all ease-in-out .3s;
}

.btn-outline-error{
  padding: 14px 20px !important;
  color: #dc3545;
  border: 1px solid #dc3545;
  border-radius: 6px;
  background-color: #fff;
  transition: all ease-in-out .3s;
  text-decoration: none;
}

.btn-outline-error:hover{
  color: #fff;
  background-color: #dc3545;
  transition: all ease-in-out .3s;
}


.not-show{
  opacity: 0 !important;
  z-index: -200000 !important;
}

.form-container {
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 8px;
  box-shadow: $defaultBoxShadow;
  .form-header {
    display: flex;
    margin-bottom: 1rem;
    margin-top: 0.25rem;
    justify-content: space-between;
    flex-wrap: wrap;
    .banner-login__img{
      width: 120px;
      height: fit-content;
    }
  }
  .form-body-three-cols{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 0.5rem;
    .full-width {
      grid-column: span 3;
    }
    .two-cols {
      grid-column: span 2;
    }
    .half-cols {
      grid-column: span 1.5;
    }
  }
}

.grid-two-cols {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.5rem;
  .full-width {
    grid-column: span 2;
  }
}

.error-message{
  transition: all ease-in-out .3s;
  font-size: 0.8rem;
  color: red;
}




@media(max-width:768px) {
  .form-body-three-cols{
    grid-template-columns: repeat(1, 1fr) !important;
    .full-width {
      grid-column: span 1 !important;
    }
    .two-cols {
      grid-column: span 1 !important;
    }
  }
}



$primary: #007a93;

$secondary: #009cbc;

$tertiary: #ed1b24;

$title-color: #5c5b5b;
$title-separator-color: #dbdbdb;

$text-color: #4f4f4f;
$underline-color: #eeeeee;

$link-color: #007a93;

$body-background: #fff;
$footer-background: #3f3f3f;
$light-background: #e7f5f8;
$defaultBoxShadow: 0 4px 8px rgba(0, 0, 0, 0.2);

$black-color: #000000;